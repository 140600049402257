import Jsona from 'jsona';
import Ajv from 'ajv';

export class JsonaFormatter {
  static instance;

  static getInstance() {
    if (!JsonaFormatter.instance) {
      JsonaFormatter.instance = new Jsona();
    }

    return JsonaFormatter.instance;
  }
}

export class SchemaValidator {
  static instance;

  static getInstance() {
    if (!SchemaValidator.instance) {
      SchemaValidator.instance = new Ajv();
    }

    return SchemaValidator.instance;
  }
}
