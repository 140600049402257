import i18n from 'i18next';
import httpBackend from 'i18next-http-backend';
import english from './translations/en.json?url';
import french from './translations/fr.json?url';
import amharic from './translations/am.json?url';
import simplifiedChinese from './translations/zh-CN.json?url';
import traditionalChinese from './translations/zh-TW.json?url';
import spanish from './translations/es.json?url';
import vietnamese from './translations/vi.json?url';
import punjabi from './translations/pa.json?url';
import tagalog from './translations/tl.json?url';
import somali from './translations/so.json?url';
import haitianCreole from './translations/ht.json?url';
import portuguese from './translations/pt.json?url';
import hindi from './translations/hi.json?url';
import korean from './translations/ko.json?url';
import german from './translations/de.json?url';
import italian from './translations/it.json?url';
import russian from './translations/ru.json?url';
import polish from './translations/pl.json?url';
import greek from './translations/el.json?url';
import japanese from './translations/ja.json?url';
import tamil from './translations/ta.json?url';
import dutch from './translations/nl.json?url';
import bengali from './translations/bn.json?url';
import gujarati from './translations/gu.json?url';
import ukrainian from './translations/uk.json?url';
import romanian from './translations/ro.json?url';
import arabic from './translations/ar.json?url';
import urdu from './translations/ur.json?url';
import thai from './translations/th.json?url';
import hebrew from './translations/he.json?url';
import khmer from './translations/km.json?url';
import persian from './translations/fa.json?url';
import armenian from './translations/hy.json?url';
import burmese from './translations/my.json?url';
import pashto from './translations/ps.json?url';
import swahili from './translations/sw.json?url';
import turkish from './translations/tr.json?url';
import yiddish from './translations/yi.json?url';
import oromo from './translations/om.json?url';
import hmong from './translations/hmn.json?url';

export const LANGUAGES = {
  en: { title: 'English', localTitle: 'English', translation: english },
  fr: { title: 'French', localTitle: 'Français', translation: french },
  am: { title: 'Amharic', localTitle: 'አማርኛ', translation: amharic },
  'zh-CN': { title: 'Simplified Chinese', localTitle: '简体中文', translation: simplifiedChinese },
  'zh-TW': {
    title: 'Traditional Chinese',
    localTitle: '繁體中文',
    translation: traditionalChinese,
  },
  es: { title: 'Spanish', localTitle: 'Español', translation: spanish },
  vi: { title: 'Vietnamese', localTitle: 'Tiếng Việt', translation: vietnamese },
  pa: { title: 'Punjabi', localTitle: 'ਪੰਜਾਬੀ', translation: punjabi },
  tl: { title: 'Tagalog', localTitle: 'Tagalog', translation: tagalog },
  so: { title: 'Somali', localTitle: 'Soomaali', translation: somali },
  ht: { title: 'Haitian Creole', localTitle: 'Kreyòl Ayisyen', translation: haitianCreole },
  pt: { title: 'Portuguese', localTitle: 'Português', translation: portuguese },
  hi: { title: 'Hindi', localTitle: 'हिन्दी', translation: hindi },
  ko: { title: 'Korean', localTitle: '한국어', translation: korean },
  de: { title: 'German', localTitle: 'Deutsch', translation: german },
  it: { title: 'Italian', localTitle: 'Italiano', translation: italian },
  ru: { title: 'Russian', localTitle: 'Русский язык', translation: russian },
  pl: { title: 'Polish', localTitle: 'Polski', translation: polish },
  el: { title: 'Greek', localTitle: 'ελληνικά', translation: greek },
  ja: { title: 'Japanese', localTitle: '日本語', translation: japanese },
  ta: { title: 'Tamil', localTitle: 'தமிழ்', translation: tamil },
  nl: { title: 'Dutch', localTitle: 'Nederlands', translation: dutch },
  bn: { title: 'Bengali', localTitle: 'বাংলা', translation: bengali },
  gu: { title: 'Gujarati', localTitle: 'ગુજરાતી', translation: gujarati },
  uk: { title: 'Ukrainian', localTitle: 'українська мова', translation: ukrainian },
  ro: { title: 'Romanian', localTitle: 'limba română', translation: romanian },
  ar: { title: 'Arabic', localTitle: 'عربى', translation: arabic, rtl: true },
  ur: { title: 'Urdu', localTitle: 'اُردُو', translation: urdu, rtl: true },
  th: { title: 'Thai', localTitle: 'ภาษาไทย', translation: thai },
  he: { title: 'Hebrew', localTitle: 'עִברִית', translation: hebrew, rtl: true },
  km: { title: 'Khmer', localTitle: 'ភាសាខ្មែរ', translation: khmer },
  fa: { title: 'Persian', localTitle: 'فارسی', translation: persian, rtl: true },
  hy: { title: 'Armenian', localTitle: 'հայերէն', translation: armenian },
  my: { title: 'Burmese', localTitle: 'မြန်မာဘာသာ', translation: burmese },
  ps: { title: 'Pashto', localTitle: 'پښتو', translation: pashto, rtl: true },
  sw: { title: 'Swahili', localTitle: 'Kiswahili', translation: swahili },
  tr: { title: 'Turkish', localTitle: 'Türkiye', translation: turkish, rtl: true },
  yi: { title: 'Yiddish', localTitle: 'ייִדיש', translation: yiddish, rtl: true },
  om: { title: 'Oromo', localTitle: 'Oromoo', translation: oromo },
  hmn: { title: 'Hmong', localTitle: 'Hmoob', translation: hmong },
};

const queryLanguage = window.location.pathname.split('/')[1];

export const defaultLanguage = window.gon?.defaultLanguage;

export const currentLanguage = queryLanguage in LANGUAGES ? queryLanguage : defaultLanguage;

export const i18nInitPromise = i18n.use(httpBackend).init({
  lng: currentLanguage,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
  backend: {
    loadPath: ([lng]) => LANGUAGES[lng]?.translation,
  },
});

export default i18n;
